import styled from 'styled-components'
import PageSection from 'components/PageSection'
import useTheme from 'hooks/useTheme'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import Hero from './components/Hero'
import { swapSectionData, earnSectionData, cakeSectionData } from './components/SalesSection/data'
import SalesSection from './components/SalesSection'
import CakeDataRow from './components/CakeDataRow'
import MultipleBanner from './components/Banners/MultipleBanner'
import RoadMap from './components/RoadMap'
import Presale from './components/Presale'
import CandyVerse from './components/CandyVerse'

const StyledHeroSection = styled(PageSection)`
  padding-top: 0px;
  background: none;
  background-image: url('/images/home/hero-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  & > div {
    padding-top: 0px;
  }
`
const PresaleSection = styled(PageSection)`
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  background: #89d5ff;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  const { t } = useTranslation()

  return (
    <>
      <PageMeta />
      <StyledHeroSection innerProps={{ style: { margin: '0', width: '100%' } }} index={2} hasCurvedDivider={false}>
        <MultipleBanner />
        <Hero />
      </StyledHeroSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...swapSectionData(t)} />
      </PageSection>

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background='#89d5ff'
        index={2}
        hasCurvedDivider={false}
      >
        <RoadMap />
      </PageSection>

      {/* <PresaleSection innerProps={{ style: { margin: '0', width: '100%' } }} index={2} hasCurvedDivider={false}>
        <video className="video" autoPlay muted loop id="banner-video">
          <source src="/images/file.mp4" type="video/mp4" />
        </video>
        <Presale />
       </PresaleSection>  */}

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.gradients.cardHeader}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...earnSectionData(t)} />
      </PageSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...cakeSectionData(t)} />
        <CakeDataRow />
      </PageSection>

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', padding: '0' } }}
        background='#89d5ff'
        index={2}
        hasCurvedDivider={false}
      >
        <CandyVerse />
      </PageSection>
    </>
  )
}

export default Home
