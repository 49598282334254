import lotteryImage from './lottery.png'
import IFOImage from './IFO.png'
import IFOMobileImage from './IFOMobile.png'
import competitionImage from './competition.png'
import competitionMobileImage from './competitionMobile.png'
import modImage from './mod-tc-desktop.png'
import modMobileImage from './mod-tc-mobile.png'
import lotteryMobileImage from './lotteryMobile.png'
import modWhiteLogo from './mod-white-logo.png'
import icoImage from './ico.png'
import icoMobileImage from './icoMobile.png'

export {
  lotteryImage,
  IFOImage,
  competitionImage,
  modImage,
  modMobileImage,
  lotteryMobileImage,
  competitionMobileImage,
  IFOMobileImage,
  modWhiteLogo,
  icoImage,
  icoMobileImage,
}
