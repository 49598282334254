import { Flex, Button, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import Fade from 'react-reveal/Fade'

const HeaderTitle = styled.h1`
  font-size: 35px;
  font-weight: 500;
  color: #000;
  width: fit-content;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Space Grotesk', sans-serif;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 50px;
  @media screen and (max-width: 450px) and (orientation: portrait) {
    // flex-direction: column;
    flex-wrap: wrap;
  }

  & > img {
    max-width: 100%;
    
    ${({ theme }) => theme.mediaQueries.sm} {
      max-width: 480px;  
    }
  }
`
const CandyVerse = () => {
  return (
    <>
      <Container>
        <Flex flex="1" flexDirection="column" alignItems={['center', null, null, 'center']} justifyContent="center">
          <HeaderTitle>Get Ready to Enter the Candy Verse</HeaderTitle>
          <Button>
            <RouterLink to="/#">
              <Text color="card" bold fontSize="16px">
                Join Now
              </Text>
            </RouterLink>
          </Button>
        </Flex>
        <Fade bottom>
          <Wrapper>
            <img src="/images/background3 (1).webp" alt="" />
          </Wrapper>
        </Fade>
      </Container>
    </>
  )
}

export default CandyVerse
