import { Flex, Text, Button, Link } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import CompositeImage, { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSectionProps {
  headingText: string
  bodyText: string
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton: SalesSectionButton
  images: CompositeImageProps
}

const BuyBtn = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background: #ff2d98;
  padding: 8px 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: fit-content;
  cursor: pointer;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  margin-right: 10px;
  display: flex;
  align-items: center;
  &:hover {
    background: #c40367;
  }
  svg : {
  }
  @media screen and (max-width: 450px) and (orientation: portrait) {
    font-size: 12px;
  }
`

const SalesSection: React.FC<React.PropsWithChildren<SalesSectionProps>> = (props) => {
  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images } = props

  return (
    <Flex flexDirection="column">
      <Fade bottom>
        <Flex
          flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
          alignItems={['flex-end', null, null, 'center']}
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            flex="1"
            ml={[null, null, null, reverse && '64px']}
            mr={[null, null, null, !reverse && '64px']}
            alignSelf={['flex-start', null, null, 'center']}
          >
            <ColoredWordHeading text={headingText} />
            <Text color="textSubtle" fontSize="18px" mb="24px">
              {bodyText}
            </Text>
            <Flex>
              <Button mr="16px">
                {primaryButton.external ? (
                  <Link external href={primaryButton.to}>
                    <Text color="card" bold fontSize="16px">
                      {primaryButton.text}
                    </Text>
                  </Link>
                ) : (
                  <RouterLink to={primaryButton.to}>
                    <Text color="card" bold fontSize="16px">
                      {primaryButton.text}
                    </Text>
                  </RouterLink>
                )}
              </Button>
              {secondaryButton.external ? (
                <Link external href={secondaryButton.to}>
                  {secondaryButton.text}
                </Link>
              ) : (
                <RouterLink to={secondaryButton.to}>{secondaryButton.text}</RouterLink>
              )}
            </Flex>
          </Flex>
          <Flex
            height={['192px', null, null, '100%']}
            width={['192px', null, null, '100%']}
            flex={[null, null, null, '1']}
            mb={['24px', null, null, '0']}
          >
            <CompositeImage {...images} />
          </Flex>
        </Flex>
      </Fade>
    </Flex>
  )
}

export default SalesSection
