import { Flex, Heading, Text } from '@pancakeswap/uikit'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'

const RadmapContainer = styled.div`
  width: 100%;
`

const RadmapWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-wrap: no-wrap;
  }
`
const Node = styled.div`
  width: calc(45%);
  margin-top: 30px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: calc(20%);
  }

  img {
    width: 100%;
    border-radius: 10px;
    border: 2px #000 solid;
    margin-bottom: 30px;
  }

  & > h2 {
    margin-bottom: 16px;
  }
`
const RoadMap = () => {
  return (
    <>
      <RadmapContainer id="roadmap">
        <Flex flex="1" flexDirection="column" alignItems="center">
          <Heading scale="xl" mb="12px">
            Roadmap
          </Heading>
          <Text fontSize="18px" textAlign="center" maxWidth="960px">
            Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token.
            Check the detailed roadmap here.
          </Text>
        </Flex>
        <Fade bottom>
          <RadmapWrapper>
            <Node>
              <img src="/images/roadmap-swap.webp" alt="" />
              <Heading scale="md">Community owned DEX</Heading>
              <Text color="#4d466a">
                Candy City is the cheapest DEX out there with only 0.15% swapping fees. 0.10% to liquidity providers &
                0.05 for buy backs of $CANDY so all of it back to you.
              </Text>
            </Node>

            <Node>
              <img src="/images/roadmap-nft.webp" alt="" />
              <Heading scale="md">Bored Candy NFT</Heading>
              <Text color="#4d466a">
                Bored Candy is an NFT collection that has taken Cronos by storm. Not only with the creativity of the
                art but also with all the utility around it.
              </Text>
            </Node>

            <Node>
              <img src="/images/roadmap-play2earn.webp" alt="" />
              <Heading scale="md">Candy City Play 2 Earn</Heading>
              <Text color="#4d466a">
                Candy City is the first P2E mobile game in cronos chain, already listed in app store & play store. 3 Playing modes: Adventure, Betting & Multiplayer.
              </Text>
            </Node>

            <Node>
              <img
                src="/images/roadmap-verse.webp"
                alt=""
              />
              <Heading scale="md">Candy Verse</Heading>
              <Text color="#4d466a">
                Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn
                in everyway possible in Cronos and every other chain out there.
              </Text>
            </Node>
          </RadmapWrapper>
        </Fade>
      </RadmapContainer>
    </>
  )
}

export default RoadMap
