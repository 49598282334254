import { ReactElement, useMemo } from 'react'
import PresaleBanner from '../PresaleBanner'

interface IBannerConfig {
  shouldRender: boolean
  banner: ReactElement
}

/**
 * make your custom hook to control should render specific banner or not
 * add new campaign banner easily
 *
 * @example
 * ```ts
 *  {
 *    shouldRender: isRenderIFOBanner,
 *    banner: <IFOBanner />,
 *  },
 * ```
 */
export const useMultipleBannerConfig = () => {
  // const isRenderIFOBanner = useIsRenderIfoBanner()
  // const isRenderLotteryBanner = useIsRenderLotteryBanner()
  // const isRenderCompetitionBanner = useIsRenderCompetitionBanner()

  // return useMemo(() => {
  //   const NO_SHUFFLE_BANNERS: IBannerConfig[] = [
  //     {
  //       shouldRender: isRenderIFOBanner,
  //       banner: <IFOBanner />,
  //     },
  //   ]

  //   const SHUFFLE_BANNERS: IBannerConfig[] = [
  //     {
  //       shouldRender: isRenderCompetitionBanner,
  //       banner: <CompetitionBanner />,
  //     },
  //     {
  //       shouldRender: isRenderLotteryBanner,
  //       banner: <LotteryBanner />,
  //     },
  //     {
  //       shouldRender: true,
  //       banner: <PerpetualBanner />,
  //     },
  //   ]
  //   return [...NO_SHUFFLE_BANNERS, ...shuffle(SHUFFLE_BANNERS)]
  //     .filter((bannerConfig: IBannerConfig) => bannerConfig.shouldRender)
  //     .map((bannerConfig: IBannerConfig) => bannerConfig.banner)
  // }, [isRenderIFOBanner, isRenderLotteryBanner, isRenderCompetitionBanner])
  return [<PresaleBanner />]
}
