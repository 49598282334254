import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on Cronos Chain in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://bored-candy-city.gitbook.io/bored-candy-city-1/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/',
    attributes: [{ src: 'trade', alt: t('trade') }],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn passive income with crypto.'),
  bodyText: t('Bored Candy City makes it easy to make your crypto work for you.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://bored-candy-city.gitbook.io/bored-candy-city-1/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/',
    attributes: [{ src: 'earn', alt: t('earn') }],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('CANDY makes our world go round.'),
  bodyText: t(
    'CANDY token is at the heart of the Bored Candy City ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x06C04B0AD236e7Ca3B3189b1d049FE80109C7977&chainId=25',
    text: t('Buy $CANDY'),
    external: false,
  },
  secondaryButton: {
    to: 'https://bored-candy-city.gitbook.io/bored-candy-city-1/',
    text: t('Learn'),
    external: true,
  },

  images: {
    path: '/images/home/',
    attributes: [{ src: 'candy', alt: t('candy') }],
  },
})
